import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { config } from "../Config";
import mainLogo from "../assets/Images/logo-2 - Copy.png";

const Navbar = ({ scrollToSection, openModal }) => {
  const [stickyNavbar, setStickyNavbar] = useState(false);

    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setStickyNavbar(true);
      } else {
        setStickyNavbar(false);
      }
    });

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  const handleLinkClick = (section) => {
    if (scrollToSection) {
      scrollToSection(section);
    }
  };

  return (
    <div>
      <nav className={`pd-navbar  ${stickyNavbar ? "skicky-navbar" : ""}`}>
        <div className="container-box">
          <div className="row justify-space-between align-items-center">
            <div className="col-4 text-md-start text-center">
              <Link
                to={`${config.appurl}`}
                className="navbar-brand d-flex align-items-end "
              >
                <img src={mainLogo} width="80" alt="NCP logo" />
                <span className="logo-text">utri Connect</span>
              </Link>
            </div>
            <div className="col-7 text-md-end text-center contact-no">
              <p className="navlinks mb-0">
                <NavLink to={`${config.appurl}`} className="navlink">
                  <li onClick={() => handleLinkClick("home")}>Home</li>
                </NavLink>
                <NavLink to={`${config.appurl}#features`} className="navlink">
                  <li onClick={() => handleLinkClick("features")}>Features</li>
                </NavLink>
                <NavLink to={`${config.appurl}#packages`} className="navlink">
                  {" "}
                  <li onClick={() => handleLinkClick("packages")}>Packages</li>
                </NavLink>
                <NavLink to={`${config.appurl}#about`} className="navlink">
                  <li onClick={() => handleLinkClick("about-us")}>About Us</li>
                </NavLink>
                <NavLink to={`${config.appurl}beta-user`} className="navlink">
                  <li>Beta User</li>
                </NavLink>

                <NavLink
                  to={`${config.appurl}`}
                  className="navlink green-btn"
                  onClick={openModal}
                >
                  {" "}
                  Register as a Dietitian
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </nav>

      <nav className="navbar2">
        <div className="container-box">
          <div className="d-flex justify-space-between align-items-end">
            <div
              className=" text-md-start text-center"
              style={{ overflow: "visible" }}
            >
              <Link
                to="/nutriconnect-pro/website"
                className="navbar-brand d-flex align-items-end"
              >
                <img
                  src={mainLogo}
                  width="100"
                  className="main-logo"
                  alt="NCP logo"
                />
                <span className="logo-text">utri Connect</span>
              </Link>
            </div>
            <div className="mobile-beta col-6 text-right">
              <Link to={`${config.appurl}beta-user`} className="navlink">
                <p className="mb-0" style={{ color: "#3b2d8e", fontWeight:"600" }}>
                 <i className="bi bi-chevron-right" style={{fontSize:"14px", color:"#3b2d8e", fontWeight:"700"}}></i> Beta User
                </p>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
