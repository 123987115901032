import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import "../assets/css/Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "../assets/css/Modal.css";
import image3 from "../assets/Images/image3.png";
import Footer from "./Footer";
import image4 from "../assets/Images/image4.jpg";
import aboutimage from "../assets/Images/handshake2.png";

const Index = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [showModal]);

  const scrollToSection = (section) => {
    let targetSection = null;

    switch (section) {
      case "home":
        targetSection = section1Ref.current;

        break;
      case "features":
        targetSection = section2Ref.current;

        break;
      case "packages":
        targetSection = section3Ref.current;

        break;
      case "about-us":
        targetSection = section4Ref.current;

        break;

      default:
        break;
    }

    if (targetSection) {
      const topOffset = targetSection.offsetTop;
      const offset = 90;
      const scrollOptions = {
        top: topOffset - offset,
        behavior: "smooth",
      };
      // targetSection.scrollIntoView(scrollOptions);
      window.scrollTo(scrollOptions);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const updateActiveIndex = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    AOS.init({
      offset: 100, // offset (in px) from the original trigger point
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease-out",
      once: false,
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(updateActiveIndex, 5000);
    return () => clearInterval(interval);
  }, []);

  const [isSeen, setIsSeen] = useState(false);

  return (
    <div>
      <div className="outer-container">
        <Navbar scrollToSection={scrollToSection} openModal={openModal} />
        {/* Banner section */}
        <section className="bg-bannr" ref={section1Ref}>
          <div>
            <div className="container-box-main">
              <div className="row justify-content-between align-items-center">
                <div
                  className="col-md-7"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  <div className="main-banner text-start">
                    <div className="banner-innersection">
                      <h3 className="text-purple">
                        Boost Client <span>Success</span> and{" "}
                        <span>Simplify</span> <br />
                        Your Workflow with Our Comprehensive Dietitian Tool
                      </h3>

                      <div className="mt-5">
                        <a href="#" className="green-btn fs-5" onClick={openModal}>
                        Register as a Dietitian
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <img src={image3} className="banner-img" alt="NCP Banner Image"></img>
                </div>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#bdb2ff"
                fill-opacity="1"
                d="M0,224L80,197.3C160,171,320,117,480,106.7C640,96,800,128,960,117.3C1120,107,1280,53,1360,26.7L1440,0L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
              ></path>
            </svg>
          </div>
        </section>

        <div id="features" ref={section2Ref}>
          <section className="pb-5 bg-light-green mb-5 card-section">
            <div className=" container-box">
              <div className="row align-items-center justify-content-center">
                <div
                  className="col-md-10 main-banner2 justify-content-center py-5 flex-column "
                  style={{ minHeight: "auto" }}
                >
                  <h3> Key features</h3>
                  <div className="line bg-purple"></div>

                  <div className="row g-3 mt-4 flip-card-main">
                    <div className="col-4 flip-card-outer">
                      <div class="flip-card flip-box">
                        <div class="flip-card-inner">
                          <div class="flip-card-front">
                            <div class="service-block_three-icon">
                              <i className="bi bi-list new-icon" alt=""></i>
                            </div>

                            <h1>1. Customizable Diet Plans </h1>
                            <p className="text-dark">
                              Offer personalized diet plans tailored to each
                              client’s unique needs, ensuring they receive the
                              best advice to achieve their health goals.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 flip-card-outer">
                      <div class="flip-card flip-box">
                        <div class="flip-card-inner">
                          <div class="flip-card-front">
                            <div class="service-block_three-icon">
                              <i
                                className="bi bi-people-fill new-icon"
                                alt=""
                              ></i>
                            </div>

                            <h1>2. Client Management System </h1>
                            <p className="text-dark">
                              Efficiently manage your clients with a
                              user-friendly dashboard that keeps all their
                              information, progress, and communication in one
                              place.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 flip-card-outer">
                      <div class="flip-card flip-box">
                        <div class="flip-card-inner">
                          <div class="flip-card-front">
                            <div class="service-block_three-icon">
                              <i
                                className=" bi bi-speedometer2 new-icon"
                                alt=""
                              ></i>
                            </div>

                            <h1>3. Automated Progress Tracking </h1>
                            <p className="text-dark">
                              Monitor client progress with automated weekly
                              updates, detailed reports, and visual progress
                              trackers that help adjust plans as needed.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 flip-card-outer">
                      <div class="flip-card flip-box">
                        <div class="flip-card-inner">
                          <div class="flip-card-front">
                            <div class="service-block_three-icon">
                              <i className="bi bi-shield-check" alt=""></i>
                            </div>

                            <h1>4. Secure Data Handling </h1>
                            <p className="text-dark">
                              Ensure all client data is securely stored with
                              advanced encryption, providing peace of mind for
                              both you and your clients regarding privacy and
                              security.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 flip-card-outer">
                      <div class="flip-card flip-box">
                        <div class="flip-card-inner">
                          <div class="flip-card-front">
                            <div class="service-block_three-icon">
                              <i className="bi bi-envelope new-icon" alt=""></i>
                            </div>

                            <h1>5. Communication Tools</h1>
                            <p className="text-dark">
                              Stay connected with your clients through
                              integrated messaging, allowing for real-time
                              advice, support, and feedback.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 flip-card-outer">
                      <div class="flip-card flip-box">
                        <div class="flip-card-inner">
                          <div class="flip-card-front">
                            <div class="service-block_three-icon">
                              <i className="bi bi-bullseye new-icon" alt=""></i>
                            </div>

                            <h1>6. Custom Goal Setting</h1>
                            <p className="text-dark">
                              Help clients set and achieve personalized health
                              goals with tools that track their progress and
                              celebrate milestones.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* packages */}
        <section
          className="pb-5 position-relative"
          id="packages"
          ref={section3Ref}
        >
          <div className="container-box pb-md-5 ">
            <div className="row px-md-4 px-sm-2">
              <div className="text-center top-section mb-5">
                <h2 className=" text-dark-purple">Explore Our packages</h2>
                <div className="line"></div>
              </div>

              <div className="d-flex justify-content-around package-container">
                <div className="col-lg-5 px-4 main-price-card main-price-card-left">
                  <div
                    className="price-block"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    <div className="inner-box">
                      <div className="title-box">
                        <h5 className="top-badge bg-purple">Free version </h5>
                        <div className="text">Only up to 10 clients</div>
                      </div>
                      <div className="price">
                        0 <span>/monthly</span>
                      </div>
                      <div className="lower-box">
                        <ul className="price-list">
                          <li>
                            <i className="bi bi-check fs-3 me-2 text-dark-purple"></i>
                            Customizable Diet Plans
                          </li>
                          <li className="class-two">
                            <i className="bi bi-check fs-3 me-2 text-dark-purple"></i>
                            Client Management System
                          </li>
                          <li>
                            <i className="bi bi-check fs-3 me-2 text-dark-purple"></i>
                            Secure Data Handling
                          </li>
                          <li>
                            <i className="bi bi-check fs-3 me-2 text-dark-purple"></i>
                            Communication Tools
                          </li>
                        </ul>

                        <a
                          href="#"
                          className="green-btn bg-purple text-white trial-btn"
                        >
                          Choose It
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 px-4 main-price-card main-price-card-right">
                  <div
                    className="price-block"
                    data-aos="fade-left"
                    data-aos-delay="100"
                  >
                    <div className="inner-box">
                      <div className="title-box">
                        <h5 className="top-badge bg-purple">Paid version</h5>
                        <div className="text">For more than 10 clients</div>
                      </div>
                      <div className="price">
                        £49 + VAT<span>/monthly</span>
                      </div>
                      <div className="lower-box">
                        <ul className="price-list">
                          <li>
                            <i className="bi bi-check fs-3 me-2 text-dark-purple"></i>
                            All features in Free version+
                          </li>
                          <li className="class-two">
                            <i className="bi bi-check fs-3 me-2 text-dark-purple"></i>
                            Automated Progress Tracking
                          </li>
                          <li>
                            <i className="bi bi-check fs-3 me-2 text-dark-purple"></i>
                            Custom Goal Setting
                          </li>
                        </ul>

                        <a
                          href="#"
                          className="green-btn bg-purple text-white trial-btn"
                          style={{ marginTop: "58px" }}
                        >
                          Choose It
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* our story section */}
        <section className=" ourstory-section" id="about">
          <div className="text-center top-section mb-5">
            <h2 className="text-dark-purple">Our Story</h2>
            <div className="line"></div>
          </div>

          <section class="card-container">
            <div class="card-main">
              <div class="card-img">
                <img src={image4} alt="NCP Card Image" />
              </div>
              <div class="card-content">
                <p class="card-description">
                  In the early stages of our journey, we conducted an extensive
                  survey and discovered a significant gap in the market: there
                  was no single app that offered a seamless, all-encompassing
                  solution for personalized nutrition and wellness. Determined
                  to fill this void, we brought together a team of experts in
                  nutrition, technology, and user experience to develop Nutri
                  Connect Pro.
                </p>
                <p class="card-description">
                  Nutri Connect Pro is more than just an app; it's your personal
                  nutrition assistant. We provide a suite of services designed
                  to make healthy living easier and more accessible, including:
                </p>
                <ul className="modal-ul">
                  <li className="sidebox-li">
                    <p className="sidebox-privacy-text">
                      <span>Personalized Meal Plans:</span> Tailored to your
                      unique dietary needs and preferences.
                    </p>
                  </li>
                  <li className="sidebox-li">
                    <p className="sidebox-privacy-text">
                      <span>Nutritional Tracking:</span> Easy-to-use tools to
                      monitor your intake and stay on track.
                    </p>
                  </li>
                  <li className="sidebox-li">
                    <p className="sidebox-privacy-text">
                      <span>Expert Advice:</span> Access to professional
                      nutritionists and wellness tips.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </section>

        <section
          className="py-5 register-section"
          id="ourvision"
          ref={section4Ref}
        >
          <div className="break-container2">
            <img src={aboutimage} className="about-image" alt="NCP About Image"></img>

            <div class="break-container2-left">
              <div class="wpb_wrapper">
                <div className="wpb_wrapper">
                  <div className="vc_row wpb_row vc_inner vc_row-fluid unique-box">
                    <div className="unique-no wpb_column vc_column_container vc_col-sm-2 vc_col-xs-3">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                            <div className="wpb_wrapper">
                              <span>01</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="unique-content wpb_column vc_column_container vc_col-sm-10 vc_col-xs-9">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h5 className="vc_custom_heading">About Us:</h5>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p>
                                <span
                                  style={{ fontWeight: 400, fontSize: "16px" }}
                                >
                                  At Nutri Connect Pro, we are passionate about
                                  bridging the gap between health-conscious
                                  individuals and comprehensive nutritional
                                  solutions. Founded on the belief that everyone
                                  deserves easy access to personalized
                                  nutrition, we embarked on a mission to create
                                  an innovative platform that revolutionizes how
                                  people manage their dietary needs.
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid unique-box">
                    <div className="unique-container">
                      <div className="unique-no wpb_column vc_column_container vc_col-sm-2 vc_col-xs-3">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                              <div className="wpb_wrapper text-center">
                                <span>02</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="unique-content wpb_column vc_column_container vc_col-sm-10 vc_col-xs-9">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h5 className="vc_custom_heading">Our Vision:</h5>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p>
                                <span
                                  style={{ fontWeight: 400, fontSize: "16px" }}
                                >
                                  We envision a world where everyone can achieve
                                  their health goals with confidence and ease.
                                  Nutri Connect Pro is committed to continuously
                                  improving our platform, integrating the latest
                                  research, and leveraging cutting-edge
                                  technology to provide the best possible
                                  experience for our users.
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <div className="row row2">
                <div className="col-md-6">
                  <img src={image3} alt="NCP Modal Image" className="img-fluid" />
                </div>
                <div className="col-md-6 form">
                  <h3>Get Free Trial</h3>
                  <form>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className=""
                        placeholder="Enter name"
                      />
                    </div>
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter phone number"
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <div className="position-relative">
                        <input
                          type={isSeen ? "text" : "password"}
                          name="password"
                          value={formData.password}
                          onChange={handleInputChange}
                          required
                          placeholder="Enter password"
                        />
                        <a className="eye-password" onClick={() => setIsSeen(!isSeen)}>
                          <i className={`${isSeen ? "bi bi-eye-slash" : 'bi bi-eye'} text-dark`}></i>
                        </a>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmpassword}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter Confirm Password"
                      />
                    </div>
                    <div className="form-group ">
                      <button type="submit" className="form-btn me-4">
                        Book Trial
                      </button>
                      <button
                        type="submit"
                        className="form-btn"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Index;
