import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../assets/css/BetaUser.css";

const BetaUser = () => {
  const betauserRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [isSeen, setIsSeen] = useState(false);

  return (
    <div className="outer-container">
      <Navbar />
      <div className="container-box-beta" ref={betauserRef}>
        <div
          className="row  justify-content-center"
          style={{ paddingTop: "60px", paddingBottom: "50px" }}
        >
          <div className="col-md-7 beta-text-section">
            <h1 className="beta-heading ">Features For Beta Users</h1>
            <div className="beta-line bg-purple mb-5"></div>
            <div className="beta-features">
              <div className="features-container">
                <div className="d-flex align-items-center">
                  <i className="bi bi-check-circle"></i>
                  <h3>Feedback and Reporting Tools!</h3>
                </div>
              </div>
              <div className="features-container">
                <div className="d-flex align-items-center">
                  <i className="bi bi-check-circle"></i>
                  <h3>Early Access to New Features!</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="form-beta-container col-md-5">
            <div className="col-md-12 form">
              <h3>Get Free Trial</h3>
              <form>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    className=""
                    placeholder="Enter name"
                  />
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter email"
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter phone number"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="position-relative">
                    <input
                      type={isSeen ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                      placeholder="Enter password"
                    />
                    <a
                      className="eye-password"
                      onClick={() => setIsSeen(!isSeen)}
                    >
                      <i
                        className={`${
                          isSeen ? "bi bi-eye-slash" : "bi bi-eye"
                        } text-dark`}
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="form-group">
                  <label>Confirm Password</label>
                  <input
                    type="text"
                    name="subject"
                    value={formData.confirmpassword}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter Confirm Password"
                  />
                </div>
                <div className="form-group ">
                  <button type="submit" className="form-btn me-4">
                    Book Trial
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BetaUser;
